/* src/components/styles.css */

.contact-buttons-wrapper {
    position: fixed;
    bottom: 20px;
    right: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    z-index: 1000;
  }
  
  .contact-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    text-decoration: none;
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
  }
  
  .contact-button-whatsapp {
    background-color: #25D366; /* WhatsApp green */
  }
  
  .contact-button-call {
    background-color: #34b7f1; /* Call blue */
  }
  
  .contact-button:hover {
    background-color: #f1f1f1;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
  }
  
  .button-image {
    width: 30px;
    height: 30px;
  }
  